import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Layout = (props: { children: JSX.Element }) => {
    const [display, setDisplay] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const { pathname } = location;
        if (pathname === '/' || pathname === '/price' || pathname === '/send' || pathname === '/welcome') {
            setDisplay(false)
        } else {
            setDisplay(true);
        }
    }, [location])

    return (
        <>
            <div className="h-full w-full top-0 left-0">
                {
                    display ?
                        <img src="./images/jbreathingfont.png" alt="jbreathing" className='fixed right-6 w-4/5 opacity-30 bottom-6 sm:bottom-6 sm:opacity-10 -z-50 ' />
                        : <></>
                }



                {/* <p className="text-[#bfecfc39] font-[Poppins] sm:text-10xl text-6xl fixed right-0 sm:right-12 bottom-12 sm:bottom-24 -z-50 ">jBreathing
                </p> */}
                {
                    display ?
                        <img src="./images/logo1.png" alt="淨呼吸" className="absolute -z-50 top-6 left-6  " />
                        : <></>
                }
                <main>{props.children}</main>
            </div>

        </>
    );
};
export default Layout;