import {
  IconBallBowling,
  IconMoodSmile,
} from '@tabler/icons'
import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'
import { useRecoilState } from 'recoil'
import { moreInfoAtom } from '../../stores/reservation'

export const Q15 = () => {
  const [moreInfo, setMoreInfo] = useRecoilState(moreInfoAtom)
  return (
    <>
      <div className="mx-6 sm:ml-16 xl:ml-60">
        <div className="mb-12">
          <Pagination></Pagination>
          <h1>
            還有其他需要為您特別注意的事項嗎？
            <IconBallBowling className="inline " />
          </h1>
          <p>
            <IconMoodSmile className="inline mr-1" />
            若沒有，請留空白即可，謝謝您！
          </p>
        </div>
      </div>

      <div className="text-center mb-12 w-fit mx-6 sm:ml-16 xl:ml-60">
        <input
          name="more"
          type="text"
          value={moreInfo}
          placeholder="備註"
          onChange={(e) => {
            setMoreInfo(e.target.value)
          }}
          className="text-center w-52 sm:w-72 outline-none focus:w-64 sm:focus:w-96 focus:ease-in-out focus:duration-300  border-b-2 border-jw-blue"
        />
      </div>

      <NaviButtons></NaviButtons>
    </>
  )
}
