import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'
import { useRecoilValue, useRecoilState } from 'recoil'
import { usertelAtom, extensionNumAtom, usertel2Atom, extensionNum2Atom } from '../../stores/reservation'
import { useEffect, useState } from 'react'
export const Q17 = () => {
  const usertel = useRecoilValue(usertelAtom)
  const extensionNum = useRecoilValue(extensionNumAtom)

  const [usertel2, setUsertel2] = useRecoilState(usertel2Atom)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [errormsg, setErrormsg] = useState("")
  const [extensionNum2, setExtensionNum2] = useRecoilState(extensionNum2Atom)
  const [hasExten, setHasExten] = useState<boolean>(true)

  let checkTel = (usertel2: string) => {
    if (usertel2.length === 0) {
      setErrormsg("此為必填欄位")
    } else if (usertel2.length >= 1 && usertel2.length < 9) {
      setErrormsg("請輸入正確號碼")
      setIsValid(false)
    }
    else {
      setIsValid(true)
    }
  }

  let checkExten = (extensionNum2: string) => {
    if (extensionNum2 !== "") {
      setHasExten(true)
    } else {
      setHasExten(false)
    }
  }

  useEffect(() => {
    checkTel(usertel2)
    checkExten(extensionNum)
  }, [extensionNum, usertel2])


  return (
    <>
      <div className="mx-6 sm:ml-16 xl:ml-60">
        <div className="mb-12">
          <Pagination></Pagination>
          <h1>
            很重要的... 最後請再次確認您的電話號碼
          </h1>
          {/* <div>幫我們
            <span className='font-medium'>
              再填寫一次
            </span>您的連絡電話唷！
          </div> */}
          <div className='w-fit px-2 bg-slate-200'>
            <p>第一次輸入的電話為&nbsp;{usertel} {hasExten && <span>#{extensionNum}</span>}</p>
          </div>
        </div>
        <div className='text-tiny text-left mb-12'>
          <p className=''>*輸入數字即可，市話請加區碼</p>
          <p className=''>*若兩次電話不同，將以本次為主，不用再去第一次修改囉！</p>
        </div>

      </div >

      <div className="text-center mb-12 flex justify-center items-start w-fit mx-6 sm:ml-16 xl:ml-60">
        <div>
          <input
            name="tel"
            maxLength={10}
            value={usertel2}
            onChange={(e) => {
              setUsertel2(e.target.value)
            }}
            onBlur={(e) => {
              checkTel(e.target.value)
            }}
            placeholder="請再次填寫您的電話號碼"
            required
            className="text-center w-48 sm:w-72 outline-none focus:w-52 sm:focus:w-80 focus:ease-in-out focus:duration-300  border-b-2 border-jw-blue"
          />

          {!isValid && <div className='text-xs text-red-600'>{errormsg}</div>}


        </div>
        <input
          name="extensionNum2"
          maxLength={8}
          placeholder="分機"
          value={extensionNum2}
          onChange={(e) => {
            setExtensionNum2(e.target.value)
          }}
          className="text-center w-16 ml-2 outline-none border-b-2 border-jw-blue"
        />
      </div>

      <NaviButtons disableNextButton={!isValid} ></NaviButtons>
    </>
  )
}
