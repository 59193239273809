import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'
import { useRecoilState } from 'recoil'
import { recommendAtom } from '../../stores/reservation'

export const Q13 = () => {
  const [recommend, setRecommend] = useRecoilState(recommendAtom)
  return (
    <>
      <div className="mx-6 sm:ml-16 xl:ml-60">
        <div className="mb-12">
          <Pagination></Pagination>
          <h1>
            若您是透過&nbsp;
            <span className="text-jw-blue font-medium underline  decoration-sky-500 underline-offset-6 leading-loose">
              親友推薦
            </span>
            ，請填寫推薦人的
            <span className='font-medium px-2 pt-2 text-sky-500'>
              預約大名 / 推薦代碼
            </span>

          </h1>
          <p>請幫我們提供全名唷！</p>
        </div>
      </div>

      <div className="text-center mb-12 w-fit mx-6 sm:ml-16 xl:ml-60">
        <input
          name="coupon"
          type="text"
          placeholder="請輸入預約大名 / 推薦代碼"
          value={recommend}
          onChange={(e) => {
            setRecommend(e.target.value)
          }}
          required
          className="text-center w-60 sm:w-72 outline-none focus:w-64 sm:focus:w-96 focus:ease-in-out focus:duration-300  border-b-2 border-jw-blue"
        />
      </div>

      <NaviButtons></NaviButtons>
    </>
  )
}
