import React from 'react';
// import './App.css';
import { RecoilRoot } from 'recoil';
import { RouteTable } from './RouteTable';
import Layout from './components/Layout';

function App() {
  return (
    <RecoilRoot>
      <Layout>
        <div className='App'>
          <RouteTable></RouteTable>
        </div>
      </Layout>
    </RecoilRoot>
  );
}

export default App;
