import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'
import { useRecoilState } from 'recoil'
import { reservationDateAtom, otherReservationDateAtom } from '../../stores/reservation'

export const Q11 = () => {
  const [reservationDate, setReservationDate] = useRecoilState(reservationDateAtom)
  const [otherReservationDate, setOtherReservationDate] = useRecoilState(otherReservationDateAtom)

  return (
    <>
      <div className="mx-6 -mt-6 sm:ml-16 xl:ml-60">
        <div className="mb-12 ">
          <Pagination></Pagination>
          <h1>
            有指定到府服務的時間嗎？
          </h1>
          <p>詳細的服務日期及時段，客服人員會再電話跟您說明唷！</p>
        </div>
      </div>
      <label className='w-60 pl-6 pr-2 sm:w-72 text-left sm:pl-12'>
        <input
          type="radio"
          checked={reservationDate === "A"}
          value="A"
          className="genderradio"
          required
          onChange={(e) => {
            setReservationDate(e.target.value)
          }}
        />
        皆可
      </label>
      <label className='w-60 pl-6 pr-2 sm:w-72 text-left sm:pl-12'>
        <input
          type="radio"
          checked={reservationDate === "B"}
          value="B"
          className="genderradio"
          required
          onChange={(e) => {
            setReservationDate(e.target.value)
          }}
        />
        希望在平日
      </label>
      <label className='w-60 pl-6 pr-2 sm:w-72 text-left sm:pl-12'>
        <input
          type="radio"
          checked={reservationDate === "H"}
          value="H"
          className="genderradio"
          required
          onChange={(e) => {
            setReservationDate(e.target.value)
          }}
        />
        希望在假日
      </label>
      <label className='w-60 pl-6 pr-2 sm:w-72 text-left sm:pl-12'>
        <input
          type="radio"
          checked={reservationDate === "O"}
          value="O"
          className="genderradio"
          required
          onChange={(e) => {
            setReservationDate(e.target.value)
          }}
        />
        其他時間
      </label>
      <div className="text-center mt-6 mb-12">
        <input
          name="otherReservationDate"
          type="text"
          placeholder="其他時間"
          value={otherReservationDate}
          onChange={(e) => {
            setOtherReservationDate(e.target.value)
          }}
          className="text-center w-52 sm:w-72 outline-none focus:w-64 sm:focus:w-96 focus:ease-in-out focus:duration-300  border-b-2 border-jw-blue"
        />
      </div>

      <NaviButtons></NaviButtons>
    </>
  )
}
