import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'
import { useRecoilState } from 'recoil'
import { usertelAtom, extensionNumAtom } from '../../stores/reservation'
import { useEffect, useState } from 'react'

export const Q04 = () => {
  const [usertel, setUsertel] = useRecoilState(usertelAtom)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [errormsg, setErrormsg] = useState("")
  const [extensionNum, setExtensionNum] = useRecoilState(extensionNumAtom)

  let checkTel = (usertel: string) => {
    if (usertel.length === 0) {
      // console.log(usertel.length);
      setErrormsg("此為必填欄位")
    } else if (usertel.length >= 1 && usertel.length < 9) {
      setErrormsg("請輸入正確號碼")
      setIsValid(false)
    }
    else {
      setIsValid(true)
    }
  }

  useEffect(() => {
    checkTel(usertel)
  }, [usertel])


  return (
    <>
      <div className="mx-6 sm:ml-16 xl:ml-60 ">
        <div className="mb-12">
          <Pagination></Pagination>
          <h1>
            請問您的聯絡電話是幾號呢？
          </h1>
          <p>
            淨呼吸客服人員&nbsp;
            <span className="underline decoration-sky-500 underline-offset-6">
              會撥打電話跟您確認&nbsp;
              <span className=" font-medium text-sky-500">預約時間</span>及
              <span className=" font-medium text-sky-500">說明其他細節</span>
            </span>
          </p>
        </div>
      </div>

      <div className="text-center mb-12 flex justify-start items-start mx-6 sm:ml-16 xl:ml-60">

        <div>
          <input
            name="tel"
            maxLength={10}
            // type="tel"
            placeholder="請輸入手機或市話"
            value={usertel}
            onChange={(e) => {
              setUsertel(e.target.value)
              // console.log(e.target.value.length);
            }}
            onBlur={(e) => {
              checkTel(e.target.value)
            }}
            // onFocus={
            //   (e) => {
            //     if (e.target.value === "") {
            //       setUsertel(e.target.value = "")
            //     }
            //   }}
            className="text-center w-48 sm:w-72 outline-none focus:w-52 sm:focus:w-80 focus:ease-in-out focus:duration-300  border-b-2 border-jw-blue"
          />
          {!isValid && <div className='text-xs text-red-600'>{errormsg}</div>}
          <div className='text-xs'>輸入數字即可，市話請加區碼</div>
        </div>
        <input
          name="extensionNum"
          maxLength={8}
          placeholder="分機"
          value={extensionNum}
          onChange={(e) => {
            setExtensionNum(e.target.value)
          }}
          className="text-center w-16 ml-2 outline-none border-b-2 border-jw-blue"
        />

      </div>

      {/* <NaviButtons disableNextButton={!isValid} nextPath="/line-official"></NaviButtons> */}
      <NaviButtons disableNextButton={!isValid}></NaviButtons>
    </>
  )
}
