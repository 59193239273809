import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'
import { useRecoilState } from 'recoil'
import { detailsAtom } from '../../stores/reservation'

export const Q10 = () => {
  const [details, setdetails] = useRecoilState(detailsAtom)
  return (
    <>
      <div className="mx-6 sm:ml-16 xl:ml-60">
        <div className="mb-12">
          <Pagination></Pagination>
          <h1>
            請進一步說明需求細節
          </h1>
          <span>Ex.清洗數量 or 其他服務</span>
        </div>
      </div>

      <div className="text-center mb-12 w-fit mx-6 sm:ml-16 xl:ml-60">
        <input
          name="detail"
          type="text"
          placeholder="請輸入需求細節"
          value={details}
          onChange={(e) => {
            setdetails(e.target.value)
          }}
          required
          className="text-center w-52 sm:w-72 outline-none focus:w-64 sm:focus:w-96 focus:ease-in-out focus:duration-300  border-b-2 border-jw-blue"
        />
      </div>

      <NaviButtons></NaviButtons>
    </>
  )
}
