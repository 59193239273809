const BubbleAni = () => {
    return (
        <>
            <div className='relative'>
                <div className='bubbleall bubble1 bottom-0 w-6 h-6 rounded-full bg-cyan-600/30 ml-64'></div>
                <div className='bubbleall bubble2 bottom-48 w-12 h-12 rounded-full bg-sky-500/20 ml-12'></div>
                <div className='bubbleall bubble3 bottom-0 w-8 h-8 rounded-full bg-sky-500/10 ml-2'></div>
                <div className='bubbleall bubble4 bottom-1/3 w-6 h-6 rounded-full bg-sky-600/20 left-72'></div>
                <div className='bubbleall bubble2 bottom-3/5 w-3 h-3 rounded-full bg-blue-300/10 ml-8'></div>
                <div className='bubbleall bubble1 w-16 h-16 rounded-full bg-sky-200/60 right-60'></div>
                <div className='bubbleall bubble3 w-12 h-12 rounded-full bg-sky-200/30 right-12'></div>
                <div className='bubbleall bubble2 w-24 h-24 bottom-1/2 rounded-full bg-sky-200/10 right-96'></div>
            </div>
        </>
    )
};
export default BubbleAni;