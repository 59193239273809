import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IconArrowBigRight } from '@tabler/icons'
import BubbleAni from '../components/BubbleAni'
import ResetDefault from '../components/ResetDefault'

export const WelcomePage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className='-mt-24'>
      <ResetDefault />
      <div className='relative mx-auto w-11/12 sm:flex sm:top-1/3 translate-y-1/2'>
        <div
          onClick={() => {
            navigate('/price')
          }}>
          <img src="./images/welcomepage/bubblel1.png" alt="" className='bubbleimg w-1/2 z-20 absolute sm:relative top-[12vh] sm:top-36 sm:w-full hover:scale-110 hover:transition hover:ease-in-out hover:cursor-pointer ' />
        </div>


        <div onClick={() => {
          navigate('/price')
        }} >
          <img src="./images/welcomepage/bubblel2.png" alt="" className='bubbleimg2 w-[48%] absolute top-[36vh] sm:relative sm:top-0 sm:-left-20 sm:w-full z-20 hover:scale-105 hover:transition hover:ease-in-out hover:cursor-pointer' />
        </div>
        <div onClick={() => {
          navigate('/price')
        }} className="">
          <img src="./images/welcomepage/bubbler1.png" alt="" className='bubbleimg3 w-[53%] absolute top-[2vh] sm:relative sm:w-full sm:top-20 sm:-left-16 right-0 z-20  hover:scale-110 hover:transition hover:ease-in-out hover:cursor-pointer' />
        </div>


        <div onClick={() => {
          navigate('/price')
        }}>
          <img src="./images/welcomepage/bubbler2.png" alt="" className='bubbleimg4 w-[45%] absolute top-[36vh] sm:relative sm:top-0 sm:w-full right-0 z-20 hover:scale-105 hover:transition hover:ease-in-out hover:cursor-pointer' />
        </div>




      </div >

      <div className='fixed bottom-0 w-full -z-50'>
        <BubbleAni />
        <div className='bgwave w-full h-full fixed bottom-0'>
          <img src="./images/welcomepage/welcomepagetitle.png" alt="" className='sm:w-2/3 md:w-3/5 lg:w-2/5 fixed bottom-[20vh] left-1/2 -translate-x-1/2' />

          <div className='fixed bottom-[3vh] left-1/2 -translate-x-1/2'>
            <div
              className=" bg-white/90 hover:scale-110 transition transform  duration-300 rounded-lg p-2 m-auto text-center w-60 cursor-pointer mb-12"
              onClick={() => {
                navigate('/price')
              }}
            >
              {t('welcomePage.next')}
              <span className="underline decoration-sky-500 underline-offset-6">
                淨呼吸
              </span>
              <IconArrowBigRight className="inline ml-2 animate-bounce text-sky-500/60" />
            </div>
          </div>



        </div>


      </div>


    </div >
  )
}
