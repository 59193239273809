import { useResetRecoilState } from 'recoil'
import { usernameAtom, userGenderAtom, usertelAtom, useraddAtom, aircon1numAtom, aircon2numAtom, aircon3numAtom, airconTypeAtom, otherOptionAtom, reservationDateAtom, otherReservationDateAtom, adFromAtom, otherAdFromAtom, detailsAtom, recommendAtom, couponAtom, moreInfoAtom, emailAtom, usertel2Atom, otherAircon1numAtom, otherAircon2numAtom, otherAircon3numAtom, extensionNumAtom, extensionNum2Atom } from '../stores/reservation'
import { useEffect } from 'react';

const ResetDefault = () => {
    const resetUserName = useResetRecoilState(usernameAtom)
    const resetUsergender = useResetRecoilState(userGenderAtom)
    const resetUsertel = useResetRecoilState(usertelAtom)
    const resetExtensionNum = useResetRecoilState(extensionNumAtom)
    const resetExtensionNum2 = useResetRecoilState(extensionNum2Atom)
    const resetUsertel2 = useResetRecoilState(usertel2Atom)
    const resetUseradd = useResetRecoilState(useraddAtom)
    const resetAircon1num = useResetRecoilState(aircon1numAtom)
    const resetAircon2num = useResetRecoilState(aircon2numAtom)
    const resetOtherAircon1num = useResetRecoilState(otherAircon1numAtom)
    const resetAircon3num = useResetRecoilState(aircon3numAtom)
    const resetOtherAircon2num = useResetRecoilState(otherAircon2numAtom)
    const resetAirconType = useResetRecoilState(airconTypeAtom)
    const resetOtherAircon3num = useResetRecoilState(otherAircon3numAtom)
    const resetOtherOption = useResetRecoilState(otherOptionAtom)
    const resetReservationDate = useResetRecoilState(reservationDateAtom)
    const resetOtherReservationDate = useResetRecoilState(otherReservationDateAtom)
    const resetAdFrom = useResetRecoilState(adFromAtom)
    const resetOtherAdFrom = useResetRecoilState(otherAdFromAtom)
    const resetDetails = useResetRecoilState(detailsAtom)
    const resetRecommend = useResetRecoilState(recommendAtom)
    const resetCoupon = useResetRecoilState(couponAtom)
    const resetMoreInfo = useResetRecoilState(moreInfoAtom)
    const resetEmail = useResetRecoilState(emailAtom)

    useEffect(() => {
        resetUserName()
        resetUsergender()
        resetUsertel()
        resetExtensionNum()
        resetExtensionNum2()
        resetUsertel2()
        resetUseradd()
        resetAircon1num()
        resetAircon2num()
        resetOtherAircon1num()
        resetAircon3num()
        resetOtherAircon2num()
        resetAirconType()
        resetOtherAircon3num()
        resetOtherOption()
        resetReservationDate()
        resetOtherReservationDate()
        resetAdFrom()
        resetOtherAdFrom()
        resetDetails()
        resetRecommend()
        resetCoupon()
        resetMoreInfo()
        resetEmail()
    }, [resetAdFrom, resetAircon1num, resetAircon2num, resetAircon3num, resetAirconType, resetCoupon, resetDetails, resetEmail, resetExtensionNum, resetExtensionNum2, resetMoreInfo, resetOtherAdFrom, resetOtherAircon1num, resetOtherAircon2num, resetOtherAircon3num, resetOtherOption, resetOtherReservationDate, resetRecommend, resetReservationDate, resetUserName, resetUseradd, resetUsergender, resetUsertel, resetUsertel2])
    return (
        <>
        </>
    )

};
export default ResetDefault;