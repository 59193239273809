import { atom } from "recoil";

export const formStepAtom = atom({
    key: 'formStep',
    default: 1
})

export const formStepsAtom = atom({
    key: 'formSteps',
    default: [1] as number[]
})

export const usernameAtom = atom({
    key: 'usernameAtom',
    default: ''
})

export const userGenderAtom = atom({
    key: 'userGenderAtom',
    default: 'male'
})

export const usertelAtom = atom({
    key: 'usertelAtom',
    default: ''
})

export const extensionNumAtom = atom({
    key: 'extensionNumAtom',
    default: ''
})

export const usertel2Atom = atom({
    key: 'usertel2Atom',
    default: ''
})

export const extensionNum2Atom = atom({
    key: 'extensionNum2Atom',
    default: ''
})

export const useraddAtom = atom({
    key: 'useraddAtom',
    default: ''
})

export const buildingNameAtom = atom({
    key: 'buildingNameAtom',
    default: ''
})
export const buildingTypeAtom = atom({
    key: 'buildingTypeAtom',
    default: ''
})

export const hasElevatorAtom = atom({
    key: 'hasElevatorAtom',
    default: ''
})

export const aircon1numAtom = atom({
    key: 'aircon1numAtom',
    default: '0'
})

export const otherAircon1numAtom = atom({
    key: 'otherAircon1numAtom',
    default: ''
})

export const aircon2numAtom = atom({
    key: 'aircon2numAtom',
    default: '0'
})
export const otherAircon2numAtom = atom({
    key: 'otherAircon2numAtom',
    default: ''
})

export const aircon3numAtom = atom({
    key: 'aircon3numAtom',
    default: '0'
})
export const otherAircon3numAtom = atom({
    key: 'otherAircon3numAtom',
    default: ''
})
export const airconTypeAtom = atom({
    key: 'airconTypeAtom',
    default: '無'
})

export const otherOptionAtom = atom({
    key: 'otherOptionAtom',
    default: ''
})
export const reservationDateAtom = atom({
    key: 'reservationDateAtom',
    default: 'A'
})
export const otherReservationDateAtom = atom({
    key: 'otherReservationDateAtom',
    default: ''
})
export const adFromAtom = atom({
    key: 'adFromAtom',
    default: ''
})
export const otherAdFromAtom = atom({
    key: 'otherAdFromAtom',
    default: ''
})

export const detailsAtom = atom({
    key: 'detailsAtom',
    default: ''
})


export const recommendAtom = atom({
    key: 'recommendAtom',
    default: ''
})

export const couponAtom = atom({
    key: 'couponAtom',
    default: ''
})

export const moreInfoAtom = atom({
    key: 'moreInfoAtom',
    default: ''
})

export const emailAtom = atom({
    key: 'emailAtom',
    default: ''
})