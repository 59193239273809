import { IAirConReservation } from './interfaces/aircon'
import { ICargo } from './interfaces/common'

export const useAPI = (projectCode: string) => {
  return {
    publicPost(endPoint: string, body: any, cb?: Function) {
      fetch(`${endPoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Project-Code': projectCode,
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          return res.status === 200 ? res.json() : { returnCode: res.status }
        })
        .then((cargo: ICargo) => {
          if (cb !== undefined) {
            cb(cargo)
          }
        })
    },
    makeReservation(reservation: IAirConReservation, cb?: Function) {
      this.publicPost(`/api/public/jhb/v5/reservations`, reservation, cb);
    }
  }
}
