import { useTranslation } from 'react-i18next'
import {
  IconUserCheck,
  IconUserX,
  IconHome,
} from '@tabler/icons'
import { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { formStepAtom } from '../../stores/reservation'
import { Pagination } from '../Pagination'
import { useNavigate } from 'react-router-dom'

export const Q01 = () => {
  const { t } = useTranslation()
  const setFormStep = useSetRecoilState(formStepAtom)
  const [errormsg, setErrormsg] = useState('')
  const handleClick = () => {
    setErrormsg('請同意條款和條件')
  }
  const navigate = useNavigate()
  return (
    <div>
      <div className="text-center sm:-mt-16 -mt-6 ">
        <Pagination></Pagination>
        <h1 className="text-jw-blue underline underline-offset-6  decoration-sky-500 text-center font-medium py-2 rounded-xl ">
          個人資料使用同意書
        </h1>
        <div className="lg:mx-36 mt-6 p-6 text-base leading-6 tracking-wider bg-jw-beige border mx-6 border-sky-200 text-left text-jw-black">

          <p className="mt-2 ">
            &emsp;&emsp;淨呼吸冷氣清洗專家暨華旺環球有限公司非常重視您的個人資料保護，因此我們制訂了個資保護聲明，我們於運用您的個人資料均遵守中華民國「個人資料保護法」相關要求，請您閱讀本同意書，以瞭解您的相關權益：
          </p>
          <div className='overflow-y-auto h-80 sm:h-48 sm:mx-6 my-6 rounded-xl px-6 bg-sky-200/30 '>
            <p className="mt-6 ">
              一、當您提供您的個人資料包括姓名、電話及住址等資料時，您的個人資料會被我們蒐集並受到安全的保護，我們會將您所提供的資料於本公司作為客戶管理、統計調查與分析、資訊與資料庫管理之執行、處理及利用等用途。
            </p>

            <p className="mt-6">
              二、您同意本公司以您所提供的個人資料與您進行聯絡以及提供您本公司辦理之活動訊息、服務及其相關事項聯繫。
            </p>

            <p className="mt-6">
              三、您可依中華民國「個人資料保護法」，就您的個人資料行使以下權利：(1)
              請求查詢或閱覽。(2) 製給複製本。(3) 請求補充或更正。(4)
              請求停止蒐集、處理及利用。(5) 請求刪除。若您欲行使前開權利，請來電
              04-3700-7929，我們將協助處理。
            </p>

            <p className="mt-6">
              四、本同意書如有未盡事宜，本公司將依個人資料保護法或其他相關法規及後修訂之規定辦理。
            </p>
            <p className="mt-6 ">
              五、您瞭解此一同意書符合個人資料保護法及相關法規之要求，且瞭解其內容，並同意本同意書所列之事項。
            </p>
          </div>


          <p className="mt-6 ">
            *本網站內容資料皆受著作權法、商標法、營業秘密法、個人資料保護法及其他相關法令規定保護，非依法令或經華旺環球有限公司書面同意或授權，不得透過任何非法或未獲授權之方式進入本網站取得任何資料，亦不得任意重製、摘錄、轉載、擷取、散布、改作其全部或部分內容。
          </p>

          <p className="mt-6 text-right font-medium">華旺環球有限公司 敬啟</p>

          <p className="mt-6">
            ● 當您勾選<span className=" text-jw-red">「我接受」</span>
            時，表示您已閱讀、瞭解並同意接受本同意書之所有內容及其後修改變更規定。
          </p>
          <div className='text-center mt-6'>
            <div
              className="mt-6 sm:mt-0 inline-block rounded-lg p-2 m-auto text-center w-48 cursor-pointer text-jw-blue font-medium  sm:mx-3"
              onClick={() => {
                navigate('/welcome')
              }}
            >
              <IconHome className="inline mr-2 bg-sky-500 rounded-full text-white p-1" />
              回首頁
            </div>
          </div>
        </div>

        <div className="mt-6 flex flex-col sm:flex-row sm:justify-center sm:mx-6 xl:mx-72 mb-16">

          <div>
            <div
              className="inline-block border rounded-lg p-2 m-auto text-center w-48 cursor-pointer hover:bg-sky-100 sm:mx-3"
              onClick={handleClick}
            >
              <IconUserX className="inline mr-2 text-jw-red" />
              {t('basicInfo.notaccept')}

            </div>
            <p className="text-red-500">{errormsg}</p>
          </div>

          <div>
            <div
              className="mt-6 sm:mt-0 inline-block border rounded-lg p-2 m-auto text-center w-48 cursor-pointer border-sky-500 hover:bg-sky-100 sm:mx-3"
              onClick={() => {
                setFormStep(2)
              }}
            >
              {t('basicInfo.next')}
              <IconUserCheck className="inline ml-2 text-sky-500" />
            </div>
          </div>

          {/* <DefaultButton name="下一步" /> */}
        </div>
      </div>
    </div>
  )
}
