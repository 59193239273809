import { IconArrowLeft, IconArrowRight } from '@tabler/icons'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { formStepAtom, formStepsAtom } from '../stores/reservation'

export type NaviButtonsProps = {
  prevPath?: string
  nextPath?: string
  disablePrevButton?: Boolean
  disableNextButton?: Boolean
  onClickNextButton?: () => void
}
export const NaviButtons = ({ prevPath, nextPath, disablePrevButton, disableNextButton, onClickNextButton }: NaviButtonsProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [formStep, setFormStep] = useRecoilState(formStepAtom)
  const [formSteps, setFormSteps] = useRecoilState(formStepsAtom)

  useEffect(() => {
    if (formSteps.length < 1) {
      setFormStep(1)
      setFormSteps([1])
      return
    } else if (formSteps.length > 16) {
      navigate('/confirm')
      return
    }
    if (prevPath === nextPath && prevPath !== undefined && nextPath !== undefined) {
      setFormStep(formSteps[formSteps.length - 1]);
    } else {
      setFormStep(formSteps[formSteps.length - 1] + 1);
    }
  }, [formSteps, navigate, nextPath, prevPath, setFormStep, setFormSteps]);

  return (
    <>
      <div className="flex sm:mx-36 mb-12">
        <div
          className={`border rounded-lg m-auto text-center w-36 cursor-pointer hover:bg-sky-100 transition transform hover:-translate-x-3 duration-300  border-sky-500 ${disablePrevButton ? 'disabled' : ''}`}
          onClick={() => {
            if (disablePrevButton) {
              return
            }
            setFormSteps(formSteps => formSteps.slice(0, formSteps.length - 1))
            if (prevPath) {
              navigate(prevPath)
            }
          }}
        >
          {t('PreviewsPage')}
          <IconArrowLeft className="inline-block ml-2 text-sky-500/60 " />
        </div>

        <div
          className={`border rounded-lg m-auto text-center w-36 cursor-pointer hover:bg-sky-100 transition transform hover:translate-x-3 duration-300 ${disableNextButton ? 'border-gray-500 bg-gray-200 text-gray-500' : 'border-sky-500'}`}
          onClick={() => {
            if (disableNextButton) {
              return
            }
            if (onClickNextButton) {
              onClickNextButton()
            }
            setFormSteps(formSteps => [...formSteps, formStep])
            if (nextPath) {
              navigate(nextPath)
            }
          }}
        >
          {t('NextPage')}
          <IconArrowRight className="inline-block ml-2 text-sky-500/60" />
        </div>
      </div>
    </>
  )
}
