import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'
import { useRecoilState } from 'recoil'
import { otherAircon1numAtom, aircon1numAtom } from '../../stores/reservation'
import { useEffect, useState } from 'react'

export const Q06 = () => {
  // const [aircon1num, setAircon1num] = useRecoilState(aircon1numAtom)
  const [otherAircon1num, setOtherAircon1num] = useRecoilState(otherAircon1numAtom)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [dropdown, setDropdown] = useRecoilState(aircon1numAtom)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [isSelect, setIsSelect] = useState<boolean>(false)

  let checkDropDown = (dropdown: string) => {
    if (dropdown === "其他") {
      setIsDisabled(false)
      setIsSelect(true)
    } else {
      setIsDisabled(true)
      setIsSelect(false)
      setOtherAircon1num("")
    }
  }
  let checkOtherNum = (otherAircon1num: string) => {
    if (dropdown === "其他" && otherAircon1num === "") {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }
  useEffect(() => {
    checkDropDown(dropdown)
    checkOtherNum(otherAircon1num)
  }, [dropdown, otherAircon1num]);

  return (
    <>
      <div className="mx-6 -mt-6 sm:ml-16 xl:ml-60 ">
        <div className="mb-12">
          <Pagination></Pagination>
          <h1>
            您想要清洗
            <div className='inline mx-1 px-1 py-1 bg-sky-500/10 font-medium rounded-md'>
              壁掛型 室內機
            </div>
            嗎？有幾台呢？
          </h1>


          <div className=''>
            <select
              value={dropdown}
              onChange={(e) =>
                setDropdown(e.target.value)
              }
              className="text-center w-36 outline-none border-b-2 border-jw-blue mt-2 sm:mt-6 py-2">
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="其他">其他</option>
            </select>

            {isSelect && <div className='inline-block ml-2'>
              <div className='flex items-start'>
                {/* <span className='mt-8'>其他數量：</span> */}
                <div className='inline-block'>
                  <input
                    name="其他"
                    // type="number"
                    disabled={isDisabled}
                    placeholder="請輸入數量"
                    value={otherAircon1num}
                    // onFocus={(e) => {
                    //   setOtherAircon2num(e.target.value = "")
                    // }}
                    onChange={(e) => {
                      setOtherAircon1num(e.target.value)
                    }}
                    onBlur={(e) => {
                      checkOtherNum(e.target.value)
                    }}
                    className="text-center w-28 outline-none border-b-2 border-jw-blue"
                  />
                  {!isValid && <div className='text-xs text-red-600 text-center'>必填</div>}
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
      {/* <div className="text-center mb-6">
        <input
          name="aircon1num"
          type="number"
          min="0"
          placeholder="0"
          value={aircon1num}
          onFocus={(e) => {
            setAircon1num(e.target.value = "")
          }}
          onChange={(e) => {
            setAircon1num(e.target.value)
          }}
          required
          className="text-center w-60 outline-none  border-b-2 border-jw-blue"
        />
      </div> */}
      <div className='-mt-8 sm:mt-12'>
        <NaviButtons disableNextButton={!isValid}></NaviButtons>
      </div>


      <div className='fixed bottom-6 -z-10'>
        <img
          src="./images/wave01.png"
          alt="埋入型分離式室內機"
          className="m-auto hidden sm:block md:w-4/6"
        />
      </div>
      <div className='fixed -bottom-8 -z-10'>
        <img
          src="./images/wave01-mobile3.png"
          alt="埋入型分離式室內機"
          className="m-auto sm:hidden"
        />
      </div>


    </>
  )
}
