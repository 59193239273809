import { NaviButtons } from '../components/NaviButtons'

export const LineOfficial = () => {
  return (
    <>
      <div className='text-center'>
        <div className="mb-6 mx-6">
          <h1>請加入我們的官方 LINE</h1>
          <p className='font-bold text-green-700'>LINE ID：@jbwow</p>

          <p>
            {' '}
            加入後請傳送您的{' '}
            <span className="font-medium underline decoration-sky-500 underline-offset-6">
              預約大名
            </span>{' '}
            給我們唷！方便之後的聯繫
          </p>
          <img
            src="./images/jbreathingline-image.png"
            alt="LINE ID：@jbwow"
            className="w-36 m-auto mt-3"
          />
          {/* <a href="https://lin.ee/LNrUEmI">
            <img
              src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png"
              alt="加入好友"
              width={140}
              className="mx-auto mt-3"
            />
          </a> */}
        </div>

      </div>
      <NaviButtons prevPath="/form" nextPath="/form"></NaviButtons>
    </>
  )
}
