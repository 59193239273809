import { useRecoilValue } from 'recoil'
import { formStepAtom } from '../stores/reservation'

export const Pagination = () => {
  const fromStep = useRecoilValue(formStepAtom)
  return (
    <>
      <span className="text-base">
        {fromStep}
        <span className="text-sm mr-3 ml-1 text-jw-black/60">/17</span>
      </span>
    </>
  )
}
