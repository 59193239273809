// import { useState } from 'react'
import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userGenderAtom, usernameAtom } from '../../stores/reservation'
import { useTranslation } from 'react-i18next'


export const Q03 = () => {
  const { t } = useTranslation();
  // const [isCheck, setIsCheck] = useState("先生")
  const [userGender, setuserGender] = useRecoilState(userGenderAtom)
  const username = useRecoilValue(usernameAtom)

  return (
    <>
      <div className="mx-6 sm:ml-16 xl:ml-60">
        <div className="mb-12">
          <Pagination></Pagination>
          <h1>
            該如何稱呼您呢？
            <span className='customerInfo block sm:inline'>
              {username} {t(`profile.${userGender}`)}
            </span>
          </h1>
          <div>

            <label>
              <input
                type="radio"
                checked={userGender === "male"}
                value="male"
                className="genderradio"
                required
                onChange={(e) => {
                  setuserGender(e.target.value)
                }}
              />
              先生
            </label>

            <label>
              {' '}
              <input
                type="radio"
                value="female"
                checked={userGender === "female"}
                className="genderradio"
                onChange={(e) => {
                  setuserGender(e.target.value)
                }}
              />
              小姐
            </label>

            <label>
              {' '}
              <input
                type="radio"
                value="others"
                checked={userGender === "others"}
                className="genderradio"
                onChange={(e) => {
                  setuserGender(e.target.value)
                }}
              />
              其他
            </label>
          </div>

        </div>
      </div>

      <NaviButtons></NaviButtons>
    </>
  )
}
