import { useRecoilValue } from 'recoil'
import { NaviButtons } from '../components/NaviButtons'
import { usernameAtom, userGenderAtom, usertelAtom, useraddAtom, buildingNameAtom, buildingTypeAtom, hasElevatorAtom, aircon1numAtom, aircon2numAtom, aircon3numAtom, airconTypeAtom, otherOptionAtom, reservationDateAtom, otherReservationDateAtom, adFromAtom, otherAdFromAtom, detailsAtom, recommendAtom, couponAtom, moreInfoAtom, emailAtom, usertel2Atom, otherAircon1numAtom, otherAircon2numAtom, otherAircon3numAtom, extensionNumAtom, extensionNum2Atom } from '../stores/reservation'
import { useEffect, useState } from 'react'
import { useAPI } from '../API'
import { ICargo } from '../interfaces/common'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const ConfirmPage = () => {
  const api = useAPI('JBH')
  const { t } = useTranslation();
  const navigate = useNavigate();
  const username = useRecoilValue(usernameAtom)
  const usergender = useRecoilValue(userGenderAtom)
  const usertel = useRecoilValue(usertelAtom)
  const extensionNum = useRecoilValue(extensionNumAtom)
  const extensionNum2 = useRecoilValue(extensionNum2Atom)
  const usertel2 = useRecoilValue(usertel2Atom)
  const useradd = useRecoilValue(useraddAtom)
  const buildingName = useRecoilValue(buildingNameAtom)
  const buildingType = useRecoilValue(buildingTypeAtom)
  const hasElevator = useRecoilValue(hasElevatorAtom)
  const aircon1num = useRecoilValue(aircon1numAtom)
  const aircon2num = useRecoilValue(aircon2numAtom)
  const otherAircon1num = useRecoilValue(otherAircon1numAtom)
  const aircon3num = useRecoilValue(aircon3numAtom)
  const otherAircon2num = useRecoilValue(otherAircon2numAtom)
  const airconType = useRecoilValue(airconTypeAtom)
  const otherAircon3num = useRecoilValue(otherAircon3numAtom)
  const otherOption = useRecoilValue(otherOptionAtom)
  const reservationDate = useRecoilValue(reservationDateAtom)
  const otherReservationDate = useRecoilValue(otherReservationDateAtom)
  const adFrom = useRecoilValue(adFromAtom)
  const otherAdFrom = useRecoilValue(otherAdFromAtom)
  const details = useRecoilValue(detailsAtom)
  const recommend = useRecoilValue(recommendAtom)
  const coupon = useRecoilValue(couponAtom)
  const moreInfo = useRecoilValue(moreInfoAtom)
  const email = useRecoilValue(emailAtom)

  const [hasExten, setHasExten] = useState<boolean>(true)
  const [hasExten2, setHasExten2] = useState<boolean>(true)

  let checkExten = (extensionNum: string) => {
    if (extensionNum !== "") {
      setHasExten(true)
    } else {
      setHasExten(false)
    }
  }
  let checkExten2 = (extensionNum2: string) => {
    if (extensionNum2 !== "") {
      setHasExten2(true)
    } else {
      setHasExten2(false)
    }
  }

  useEffect(() => {
    checkExten(extensionNum)
    checkExten2(extensionNum2)
  }, [extensionNum, extensionNum2])

  const handleSubmit = () => {

    let reservedProducts = [];
    if (otherAircon1num || aircon1num) {
      reservedProducts.push({
        idProducts: Number(process.env.REACT_APP_AIRCON_1),
        qty: Number(otherAircon1num || aircon1num)
      })
    }
    if (otherAircon2num || aircon2num) {
      reservedProducts.push({
        idProducts: Number(process.env.REACT_APP_AIRCON_2),
        qty: Number(otherAircon2num || aircon2num)
      })
    }
    if (otherAircon3num || aircon3num) {
      reservedProducts.push({
        idProducts: Number(process.env.REACT_APP_AIRCON_3),
        qty: Number(otherAircon3num || aircon3num)
      })
    }

    let remarks = [];
    remarks.push(airconType);
    remarks.push(otherOption);
    remarks.push(otherAdFrom);
    remarks.push(details);
    remarks.push(recommend);
    remarks.push(moreInfo);

    let gender = 'O';
    if (usergender === 'male') {
      gender = 'M';
    }
    if (usergender === 'female') {
      gender = 'F';
    }
    api.makeReservation({
      legalName: username,
      phone: `${usertel2}${extensionNum2 ? `#${extensionNum2}` : ''}`,
      reservedProducts: reservedProducts,
      availableSlot: reservationDate,
      serviceAddress: useradd,
      buildingName: buildingName,
      hasElevator: hasElevator === 'true',
      buildingType: buildingType,
      gender: gender,
      email: email,
      sourceFrom: adFrom,
      couponToken: coupon,
      referral: recommend,
      remark: remarks.join(', '),
    }, (cargo: ICargo) => {
      // console.log(cargo);
      if (cargo.returnCode === '000000') {
        navigate('/send')
      } else {
        alert(`不好意思服務發生問題，請聯繫客服，謝謝。${cargo.returnCode}`)
      }
    })
  };

  return (
    <>
      <div className='mx-6 sm:mx-16 lg:mx-20 xl:mx-60 mb-6 sm:-mt-16'>
        <h1 className='text-jw-blue font-medium underline underline-offset-6 decoration-sky-500 text-center xl:text-3xl'>請確認您的預約資訊</h1>
        <div className='xl:text-xl leading-loose showcustomerInfo'>
          <div>姓名/<span>{username}</span></div>
          <div>性別/<span>{t(`profile.${usergender}`)}</span></div>
          {/* <div>電話/<span>{usertel}#{extensionNum}</span></div> */}
          <div>電話/<span>{usertel}{hasExten && <span>#{extensionNum}</span>}</span></div>
          <div>電話確認/<span>{usertel2}{hasExten2 && <span>#{extensionNum2}</span>}</span></div>
          <div>地址/<span>{useradd}</span></div>
          <div>大樓名稱/<span>{buildingName}</span></div>
          <div>住宅型態/<span>{t(`buildingType.${buildingType}`)}</span></div>
          <div>是否有電梯/<span>{t(`hasElevator.${hasElevator}`)}</span></div>
          <div>壁掛型分離式室內機/&nbsp;<span>{aircon1num}&nbsp;{otherAircon1num} 台</span></div>
          <div>埋入型分離式室內機/&nbsp;<span>{aircon2num}&nbsp;{otherAircon2num} 台</span></div>
          <div>分離式室外主機/&nbsp;<span>{aircon3num}&nbsp;{otherAircon3num} 台</span></div>
          <div>其他類型的冷氣/<span>{airconType}&nbsp;{otherOption}</span> </div>
          <div>指定到府服務時間/<span>
            {t(`reservationDate.${reservationDate}`)}&nbsp;{otherReservationDate} </span></div>
          <div>如何得知我們/<span> {t(`adFrom.${adFrom}`)}&nbsp;{otherAdFrom}</span> </div>
          <div>其他需求/<span>{details}</span></div>
          <div>預約大名/推薦代碼/<span>{recommend}</span></div>
          <div>抵用券代碼/<span>{coupon}</span></div>
          <div>其他注意事項/<span>{moreInfo}</span></div>
          <div>Email/<span>{email}</span> </div>
        </div>

      </div>
      <NaviButtons prevPath="/form" onClickNextButton={handleSubmit}></NaviButtons>
    </>
  )
}
