import { IconMailFast } from '@tabler/icons'
import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'
import { useRecoilState } from 'recoil'
import { emailAtom } from '../../stores/reservation'
import { useEffect, useState } from 'react'

export const Q16 = () => {
  const [email, setEmail] = useRecoilState(emailAtom)
  const [isValid, setIsValid] = useState<boolean>(false)

  let checkEmail = (email: string) => {
    var regex = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if (!regex.test(email)) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }

  useEffect(() => {
    checkEmail(email)
  }, [email]);

  return (
    <>
      <div className="mx-6 sm:ml-16 xl:ml-60">
        <div className="mb-12">
          <Pagination></Pagination>
          <h1>
            請留下您的 Email
            <IconMailFast className="inline ml-2" />
          </h1>
          <p>完工後我們會直接開立電子發票給您喔！</p>
        </div>
      </div>

      <div className="text-center mb-12 w-fit mx-6 sm:ml-16 xl:ml-60">
        <input
          name="email"
          type="email"
          placeholder="請留下您的 Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          // onBlur={(e) => {
          //   checkEmail(e.target.value)
          // }}
          required
          className="text-center w-52 sm:w-72 outline-none focus:w-64 sm:focus:w-96 focus:ease-in-out focus:duration-300  border-b-2 border-jw-blue"
        />
        {!isValid && <div className='text-xs text-red-600'>此為必填欄位</div>}
      </div>

      <NaviButtons disableNextButton={!isValid}></NaviButtons>
      {/* <NaviButtons></NaviButtons> */}

    </>
  )
}
