import { IconBuildingCommunity } from '@tabler/icons'
import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'
import { useRecoilState } from 'recoil'
import { useraddAtom, buildingNameAtom, buildingTypeAtom, hasElevatorAtom } from '../../stores/reservation'
import { useEffect, useState } from 'react'

export const Q05 = () => {
  const [useradd, setUseradd] = useRecoilState(useraddAtom)
  const [buildingName, setBuildingName] = useRecoilState(buildingNameAtom)
  const [buildingType, setBuildingType] = useRecoilState(buildingTypeAtom)
  const [hasElevator, setHasElevator] = useRecoilState(hasElevatorAtom)

  const [isValid, setIsValid] = useState<boolean>(false)

  let checkAdd = (useradd: string) => {
    if (useradd === "") {
      setIsValid(false)

    } else {
      setIsValid(true)
    }
  }
  useEffect(() => {
    checkAdd(useradd)
  }, [useradd])
  return (
    <>
      <div className="mx-6 sm:ml-16 xl:ml-60">
        <div className="mb-12">
          <Pagination></Pagination>
          <h1>
            請問要清洗冷氣的地址是？
          </h1>
          請留&nbsp;
          <span className="text-jw-blue underline decoration-sky-500 underline-offset-6">
            詳細地址
          </span>
          <div className='mt-2'>
            若有
            <span className="text-jw-blue underline decoration-sky-500 underline-offset-6">
              <IconBuildingCommunity className="inline-block text-sky-500 mx-1" />
              大樓名稱
            </span>
            &nbsp;順便幫我備註一下唷！
          </div>
        </div>
      </div>

      <div className="text-center mb-12 w-fit mx-6 sm:ml-16 xl:ml-60">
        <input
          name="address"
          type="address"
          placeholder="請輸入地址"
          required
          value={useradd}
          onChange={(e) => {
            setUseradd(e.target.value)
          }}
          onBlur={(e) => {
            checkAdd(e.target.value)
          }

          }
          className="text-center w-52 sm:w-72 outline-none focus:w-64 sm:focus:w-96 focus:ease-in-out focus:duration-300  border-b-2 border-jw-blue"
        />
        {!isValid && <div className='text-xs text-red-600'>此為必填欄位</div>}
      </div>

      <div className='bg-sky-100 py-12 mb-6'>


        <div className="text-center mb-2 w-fit mx-6 sm:ml-16 xl:ml-60 ">
          <input
            name="buildingName"
            type="text"
            placeholder="大樓名稱"
            required
            value={buildingName}
            onChange={(e) => {
              setBuildingName(e.target.value)
            }}
            className="text-center w-52 sm:w-72 outline-none focus:w-64 sm:focus:w-96 focus:ease-in-out focus:duration-300  border-b-2 border-jw-blue"
          />
          {!buildingName && <div className='text-xs text-red-600'>此為必填欄位</div>}
        </div>

        <div className='mb-2 mx-6 sm:ml-16 xl:ml-60'>
          <span>住宅型態&nbsp;</span>
          <select
            value={buildingType}
            onChange={(e) =>
              setBuildingType(e.target.value)
            }
            className="text-center w-32 outline-none border-b-2 border-jw-blue mt-2 sm:mt-6 py-2">
            <option value="" disabled hidden>請選擇</option>
            <option value="B">大樓</option> {/*building*/}
            {/* <option value="C">社區</option> community */}
            <option value="A">公寓</option> {/*apartment*/}
            <option value="D">透天</option> {/*Condominium*/}
            <option value="S">店面</option> {/*Shop*/}
            <option value="O">其他</option> {/*Other*/}
          </select>
          {!buildingType && <div className='text-xs text-red-600'>此為必填欄位</div>}
        </div>

        <div className='mx-6 sm:ml-16 xl:ml-60'>
          <div className='flex items-center'>
            <span>電梯&nbsp;</span>

            <label className='px-3 py-1 border-none m-0'>
              <input
                type="radio"
                checked={hasElevator === "true"}
                value="true"
                onChange={(e) => {
                  setHasElevator(e.target.value)
                }}
              />
              &nbsp;有電梯
            </label>

            <label className='px-3 py-1 border-none m-0'>
              <input
                type="radio"
                checked={hasElevator === "false"}
                value="false"
                onChange={(e) => {
                  setHasElevator(e.target.value)
                }}
              />
              &nbsp;沒有電梯
            </label>
          </div>

        </div>
      </div>
      <NaviButtons disableNextButton={!isValid}></NaviButtons>
    </>
  )
}
