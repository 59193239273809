import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'
import { useRecoilState } from 'recoil'
import { adFromAtom, otherAdFromAtom } from '../../stores/reservation'
export const Q12 = () => {
  const [adFrom, setAdFrom] = useRecoilState(adFromAtom)
  const [otherAdFrom, setOtherAdFrom] = useRecoilState(otherAdFromAtom)

  return (
    <>
      <div className="mx-6 -mt-6 sm:ml-16 xl:ml-60">
        <div className="mb-12">
          <Pagination></Pagination>
          <h1>
            請問您是如何得知
            <div className='pb-2 px-2 text-center inline underline underline-offset-6 decoration-sky-500 text-jw-blue font-medium leading-loose'>
              淨呼吸冷氣清洗專家
            </div>
            的呢？
          </h1>
        </div>
      </div>
      <label className='w-60 pl-6 pr-2 sm:w-80 text-left sm:pl-12'>
        <input
          type="radio"
          checked={adFrom === "E"}
          value="E"
          className="genderradio"
          required
          onChange={(e) => {
            setAdFrom(e.target.value)
          }}
        />
        忠實客戶
      </label>
      <label className='w-60 pl-6 pr-2 sm:w-80 text-left sm:pl-12'>
        <input
          type="radio"
          checked={adFrom === "F"}
          value="F"
          className="genderradio"
          required
          onChange={(e) => {
            setAdFrom(e.target.value)
          }}
        />
        Facebook
      </label>
      <label className='w-60 pl-6 pr-2 sm:w-80 text-left sm:pl-12'>
        <input
          type="radio"
          checked={adFrom === "C"}
          value="C"
          className="genderradio"
          required
          onChange={(e) => {
            setAdFrom(e.target.value)
          }}
        />
        Google/Yahoo搜尋引擎
      </label>
      <label className='w-60 pl-6 pr-2 sm:w-80 text-left sm:pl-12'>
        <input
          type="radio"
          checked={adFrom === "R"}
          value="R"
          className="genderradio"
          required
          onChange={(e) => {
            setAdFrom(e.target.value)
          }}
        />
        親朋好友推薦
      </label>
      <label className='w-60 pl-6 pr-2 sm:w-80 text-left sm:pl-12'>
        <input
          type="radio"
          checked={adFrom === "O"}
          value="O"
          className="genderradio"
          required
          onChange={(e) => {
            setAdFrom(e.target.value)
          }}
        />
        其他
      </label>
      <div className="text-center mt-6 mb-12">
        <input
          name="otherAd"
          type="text"
          placeholder="其他"
          value={otherAdFrom}
          onChange={(e) => {
            setOtherAdFrom(e.target.value)
          }}
          className="text-center w-60 sm:w-80 outline-none focus:w-72 sm:focus:w-96 focus:ease-in-out focus:duration-300  border-b-2 border-jw-blue"
        />
      </div>

      <NaviButtons></NaviButtons>
    </>
  )
}
