import Card from "../components/Card"
import { IconHeadset, IconClock, IconArrowRight, IconHome } from "@tabler/icons"
import { useNavigate } from 'react-router-dom'
import BubbleAni from "../components/BubbleAni"
export const PriceList = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className="h-full w-full -mt-36 bg-[#0e90cc1a] -z-50">
                <div className="fixed bottom-0 w-full -z-50">
                    <BubbleAni />
                </div>
                <div className="pt-24 mb-12 text-center text-3xl tracking-widest font-medium text-price-blue relative w-fit mx-auto ">
                    <span className="text-5xl tracking-[0.6rem]">
                        <span className="rounded-full bg-white/80 p-2 mr-1">冷</span>
                        <span className="rounded-full bg-white/80 p-2 mr-1">氣</span>
                        機種</span>
                    <div className="mt-8 sm:mt-0 sm:inline">
                        <span className="bg-price-blue text-white p-1 ml-2">價</span>
                        <span className="bg-price-blue text-white p-1 mx-1">目</span>
                        <span className="bg-price-blue text-white p-1">表</span>
                    </div>
                </div>
                <Card />
                <div className="text-center text-3xl mt-12 tracking-widest text-price-blue font-medium ">
                    <span className="rounded-full bg-white pl-4 pr-3 py-3 mr-2">保</span>
                    <span className="rounded-full bg-white pl-4 pr-3 py-3 mr-2">養</span>
                    <span className="rounded-full bg-white pl-4 pr-3 py-3 mr-2">升</span>
                    <span className="rounded-full bg-white pl-4 pr-3 py-3 mr-2">級</span>
                    <div className="mt-8 sm:mt-0 sm:inline">
                        <span className="rounded-full bg-white pl-4 pr-3 py-3 mr-2 ml-16 sm:ml-0">方</span>
                        <span className="rounded-full bg-white pl-4 pr-3 py-3">案</span>
                    </div>

                </div>
                <div className="sm:flex md:px-12 xl:justify-center">
                    <div className="mt-10 px-8 sm:w-1/2 sm:mx-auto xl:w-2/5 2xl:mx-12">
                        <img src="./images/jbreathingprice/no1-3.png" alt="升級病毒盾方案" className="sm:w-5/6" />
                        <div className="mx-2mt-2 text-lg lg:text-xl lg:leading-10 lg:px-8 ">醫療級強效殺菌力能30秒<br />快速殺菌並降低過敏原<br />還有防黴、除臭、殺蟎的效果</div>
                        <div className="text-jw-red font-medium text-xl lg:text-2xl lg:leading-10 ">+$500/台</div>
                    </div>
                    <div className="mt-10 px-8 sm:w-1/2 sm:mx-auto xl:w-2/5 2xl:mx-1">
                        <img src="./images/jbreathingprice/no2.png" alt="升級奈米銀離子方案" className="sm:w-5/6" />
                        <div className="mt-2 mx-2 text-lg lg:text-xl lg:leading-10 lg:px-12 ">奈米銀是將貴金屬的「銀」奈米化後，接觸微生物細胞後便相互吸附，穿刺細胞外表，讓細胞無法代謝和繁殖，達到滅菌的效果</div>
                        <div className="text-jw-red font-medium text-xl lg:text-2xl lg:leading-10">+$1000/台</div>
                    </div>
                </div>

                <div className="bg-[#BFE8F2]/30 pb-6 text-tiny leading-6 font-medium sm:text-lg sm:leading-8 text-center">

                    <div className="text-right relative right-6 top-6">
                        <button onClick={() => {
                            navigate('/form')
                        }} className="border rounded-lg m-auto text-center w-36 cursor-pointer bg-sky-300 transition transform hover:translate-x-3 duration-300 border-sky-500 text-white animate-bounce py-2 text-lg">下一頁<IconArrowRight className="inline-block ml-2 text-sky-500/60 " />
                        </button>
                    </div>

                    <div className="border-white border-t pb-6 pt-8 bg-white">
                        淨呼吸エアコンクリーニングの専門家<br />
                        <div className='text-center sm:mt-6'>
                            <div
                                className="mt-6 sm:mt-0 inline-block rounded-lg p-2 m-auto text-center w-48 cursor-pointer text-jw-blue font-medium bg-sky-100 sm:mx-3"
                                onClick={() => {
                                    navigate('/welcome')
                                }}
                            >
                                <IconHome className="inline mr-2 bg-sky-500 rounded-full text-white p-1" />
                                回首頁
                            </div>
                        </div>
                        {/* 台中市西屯區府會園道179號3樓<br /> */}
                    </div>
                    <div className=" pt-3 sm:flex sm:justify-center">
                        <div>
                            <IconClock className="inline pr-1" />
                            <span className="inline pr-1 sm:mr-6">
                                09:30~17:30
                            </span>
                        </div>
                        <div>
                            <IconHeadset className="inline pr-1" />
                            <span>0809-090-878</span>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}