import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import BubbleAni from "../components/BubbleAni";

export const SendPage = () => {
    // const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <div className="-mt-24 sm:border sm:border-sky-500 mx-6">
            <div className="fixed bottom-0 w-full -z-50">
                <BubbleAni />
            </div>
            <div className="text-center ">
                <img src="./images/default-firstframe.png" alt="淨呼吸" className="block mx-auto sm:w-72 w-3/4" />
            </div>

            <div className="text-center text-xl leading-10 mb-12 underline decoration-sky-500 underline-offset-6 ">
                <p>感謝您的預約</p>
                <p>我們會盡快與您聯繫</p>
            </div>
            <a href="https://lin.ee/LNrUEmI">
                <img
                    src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png"
                    alt="加入好友"
                    width={180}
                    className="mx-auto my-3"
                />
            </a>
            <p className="mx-auto w-fit mt-1 text-left sm:text-center">
                加入後請傳送您的
                <span className="font-medium underline decoration-sky-500 underline-offset-6">
                    預約大名
                </span>
                <p className="sm:mb-6">方便之後的聯繫</p>
            </p>
        </div >



    )
}
