import { IconMoodKid } from '@tabler/icons'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { IFormProps } from '../../interfaces/step'
import { usernameAtom } from '../../stores/reservation'
import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'

export const Q02 = ({ required }: IFormProps) => {
  const [isValid, setIsValid] = useState<boolean>(false)
  const [username, setUsername] = useRecoilState(usernameAtom)
  useEffect(() => {
    if (required) {
      if (username.trim().length === 0) {
        setIsValid(false)
        return
      }
    }
    setIsValid(true)
  }, [isValid, required, username])

  return (
    <>
      <div className="mx-6 sm:ml-16 xl:ml-60">
        <div className="mb-12">
          <Pagination></Pagination>
          <h1>
            請問您的預約大名？
          </h1>
          <p className='text-left'>
            我們需要您的<span className="text-jw-blue">「中文全名」</span>
            以建立預約資料唷！謝謝 <IconMoodKid className="inline" />
          </p>
        </div>
      </div>
      <div className="mx-6 sm:ml-16 xl:ml-60 mb-12 w-fit">
        <input
          name="name"
          type="text"
          placeholder="請輸入您的姓名"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value)
          }}
          className="text-center w-52 sm:w-72 outline-none focus:w-64 sm:focus:w-96 focus:ease-in-out focus:duration-300 border-b-2 border-jw-blue"
        />
        {!isValid && <div className='text-xs text-red-600 text-center'>此為必填欄位</div>}
      </div>
      <NaviButtons disableNextButton={!isValid}></NaviButtons>
    </>
  )
}
