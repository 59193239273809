import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'
import { useRecoilState } from 'recoil'
import { otherAircon2numAtom, aircon2numAtom } from '../../stores/reservation'
import { useEffect, useState } from 'react'

export const Q07 = () => {
  // const [aircon2num, setAircon2num] = useRecoilState(aircon2numAtom)
  const [dropdown, setDropdown] = useRecoilState(aircon2numAtom)
  const [otherAircon2num, setOtherAircon2num] = useRecoilState(otherAircon2numAtom)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [isSelect, setIsSelect] = useState<boolean>(false)
  // useEffect(() => {
  //   if (dropdown === "其他") {
  //     // console.log("other");
  //     setIsDisabled(false)
  //   } else {
  //     setIsDisabled(true)
  //     setOtherAircon2num("")
  //   }
  // }, [dropdown, isDisabled, setOtherAircon2num]);
  let checkDropDown = (dropdown: string) => {
    if (dropdown === "其他") {
      setIsDisabled(false)
      setIsSelect(true)
    } else {
      setIsDisabled(true)
      setIsSelect(false)
      setOtherAircon2num("")
    }
  }
  let checkOtherNum = (otherAircon2num: string) => {
    if (dropdown === "其他" && otherAircon2num === "") {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }
  useEffect(() => {
    checkDropDown(dropdown)
    checkOtherNum(otherAircon2num)
  }, [dropdown, otherAircon2num]);


  return (
    <div>
      <div className="mx-6 -mt-6 sm:ml-16 xl:ml-60">
        <div className="mb-12">
          <Pagination></Pagination>
          <h1>
            您想要清洗
            <div className='inline mx-1 px-1 py-1 bg-sky-500/10 font-medium rounded-md'>
              埋入型 室內機
            </div>
            嗎？有幾台呢？
          </h1>


          <div className='my-2'>
            <select
              value={dropdown}
              onChange={
                (e) => setDropdown(e.target.value)
              }

              className="text-center w-36 outline-none border-b-2 border-jw-blue mt-2 sm:mt-6 py-2">
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="其他">其他</option>
            </select>

            {isSelect && <div className='inline-block ml-2'>
              <div className='flex items-start'>
                {/* <span className='mt-8'>其他數量：</span> */}
                <div className='inline-block'>
                  <input
                    name="其他"
                    // type="number"
                    disabled={isDisabled}
                    placeholder="請輸入數量"
                    value={otherAircon2num}
                    // onFocus={(e) => {
                    //   setOtherAircon2num(e.target.value = "")
                    // }}
                    onChange={(e) => {
                      setOtherAircon2num(e.target.value)
                    }}
                    onBlur={(e) => {
                      checkOtherNum(e.target.value)
                    }}
                    className="text-center w-28 outline-none border-b-2 border-jw-blue mt-6"
                  />
                  {!isValid && <div className='text-xs text-red-600 text-center'>必填</div>}
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>

      {/* <div className="text-center mb-6">
        <input
          name="airconpro2"
          type="number"
          min="0"
          placeholder="1"
          // value={aircon2num}
          // onChange={(e) => {
          //   setAircon2num(e.target.value)
          // }}
          required
          className="text-center w-60 outline-none  border-b-2 border-jw-blue"
        />
      </div> */}

      <div className='-mt-8 sm:mt-12'>
        <NaviButtons disableNextButton={!isValid}></NaviButtons>
      </div>

      <div className='fixed bottom-6 -z-10'>
        <img
          src="./images/wave02.png"
          alt="埋入型室內機"
          className="m-auto hidden sm:block md:w-4/6"
        />

      </div>
      <div className='fixed -bottom-8 -z-10'>
        <img
          src="./images/wave02-mobile3.png"
          alt="埋入型室內機"
          className="m-auto sm:hidden"
        />
      </div>
      <div />
    </div>
  )
}
