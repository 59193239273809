import { Route, Routes } from 'react-router-dom'
import { WelcomePage } from './pages/WelcomePage'
// import { PersonalPrivacy } from "./pages/PersonalPrivacy"

import { FormPage } from './pages/FormPage'
import { SendPage } from './pages/SendPage'
import { ConfirmPage } from './pages/ConfirmPage'
import { LineOfficial } from './pages/LineOfficial'
import { PriceList } from './pages/PriceList'

export const RouteTable = () => {
  return (
    <Routes>
      <Route path="/" element={<WelcomePage />} />
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/form" element={<FormPage />} />
      <Route path="/line-official" element={<LineOfficial />} />
      <Route path="/confirm" element={<ConfirmPage />} />
      <Route path="/send" element={<SendPage />} />
      <Route path="/price" element={<PriceList />} />
    </Routes>
  )
}
