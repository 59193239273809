import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'
import { useRecoilState } from 'recoil'
import { otherOptionAtom, airconTypeAtom } from '../../stores/reservation'
export const Q09 = () => {
  const [airconType, setAirconType] = useRecoilState(airconTypeAtom)
  const [otherOption, setOtherOption] = useRecoilState(otherOptionAtom)
  // function buttonHandler(airconType: string) {
  //   console.log("button click", airconType);
  // }
  return (
    <>
      <div className="-mt-6 mx-6 sm:ml-16 xl:ml-60">
        <div className="mb-12">
          <Pagination></Pagination>
          <h1>
            或是... 您想清洗其他類型的冷氣嗎？
          </h1>
          {/* <button onClick={() => buttonHandler("無")}>無</button >
          <button onClick={() => buttonHandler("窗型冷氣 $4500/台")}>窗型冷氣 $4500/台</button >
          <button onClick={() => buttonHandler("商用空調 (估價)")}>商用空調 (估價)</button > */}
          <label className='w-60 pl-6 pr-2 sm:w-72 text-left sm:pl-12'>
            <input
              type="radio"
              checked={airconType === "無"}
              value="無"
              className="genderradio"
              required
              onChange={(e) => {
                setAirconType(e.target.value)
              }}
            />
            無
          </label>

          <label className='w-60 pl-6 pr-2 sm:w-72 text-left sm:pl-12'>
            <input
              type="radio"
              checked={airconType === "窗型冷氣 $4500/台"}
              value="窗型冷氣 $4500/台"
              className="genderradio"
              required
              onChange={(e) => {
                setAirconType(e.target.value)
              }}
            />
            窗型冷氣 $4500/台
          </label>

          <label className='w-60 pl-6 pr-2 sm:w-72 text-left sm:pl-12'>
            <input
              type="radio"
              checked={airconType === "商用空調 (估價)"}
              value="商用空調 (估價)"
              className="genderradio"
              required
              onChange={(e) => {
                setAirconType(e.target.value)
              }}
            />
            商用空調 (估價)
          </label>
          <label className='w-60 pl-6 pr-2 sm:w-72 text-left sm:pl-12'>
            <input
              type="radio"
              checked={airconType === "其他"}
              value="其他"
              className="genderradio"
              required
              onChange={(e) => {
                setAirconType(e.target.value)
              }}
            />
            其他
          </label>

          <div className="text-center my-6">
            <input
              name="other"
              type="text"
              placeholder="其他"
              value={otherOption}
              onChange={(e) => {
                setOtherOption(e.target.value)
              }}
              className="text-center w-60 sm:w-72 outline-none focus:w-64 sm:focus:w-96 focus:ease-in-out focus:duration-300  border-b-2 border-jw-blue"
            />
            {/* <p>{otherOption}</p> */}
          </div>
        </div>
      </div>

      <NaviButtons></NaviButtons>
    </>
  )
}
