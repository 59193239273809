const Card = () => {

    return (
        <>
            <div className="sm:px-6 sm:grid sm:grid-cols-12 sm:gap-2 md:gap-3 lg:gap-4">
                <div className="w-60 py-6 my-6 bg-white border-2 border-price-blue shadow-lg mx-auto p-5 rounded-lg sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3 2xl:col-span-3 relative">
                    <div className="bg-price-lightblue absolute -top-3 w-24 rounded-lg text-white text-center font-medium">
                        分離式
                    </div>
                    <img src="./images/aircon01.png" alt="壁掛型室內機" className="mb-6 mt-6" />
                    <div className="text-center text-xl">
                        <div className="bg-price-blue  text-white [clip-path:polygon(0%_0%,100%_0%,95%_50%,100%_100%,0%_100%,5%_50%)]">壁掛型室內機</div>
                        <p className="mt-2">基本方案 $2500/台</p>
                    </div>

                </div>
                <div className="w-60 py-6 my-6 bg-white border-2 border-price-blue shadow-lg mx-auto p-5 rounded-lg sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3 2xl:col-span-3 relative">
                    <div className="bg-price-lightblue absolute -top-3 w-24 rounded-lg text-white text-center font-medium">
                        分離式
                    </div>
                    <img src="./images/aircon02.png" alt="埋入型室內機" className="mb-6 mt-6" />
                    <div className="text-center text-xl">
                        <div className="bg-price-blue  text-white [clip-path:polygon(0%_0%,100%_0%,95%_50%,100%_100%,0%_100%,5%_50%)]">埋入型室內機</div>
                        <p className="mt-2">基本方案 $3500/台</p>
                    </div>
                </div>

                <div className="w-60 py-6 my-6 bg-white border-2 border-price-blue shadow-lg mx-auto p-5 rounded-lg sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3 2xl:col-span-3">
                    <img src="./images/aircon03.png" alt="室外機" className="mb-6 mt-6" />
                    <div className="text-center text-xl">
                        <div className="bg-price-blue  text-white [clip-path:polygon(0%_0%,100%_0%,95%_50%,100%_100%,0%_100%,5%_50%)]">室外機</div>
                        <p className="mt-2">清洗費用 $800/台</p>
                    </div>
                </div>
                <div className="w-60 py-6 my-6 bg-white border-2 border-price-blue shadow-lg mx-auto p-5 rounded-lg sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3 2xl:col-span-3">
                    <img src="./images/aircon04.png" alt="窗型冷氣" className="mb-6 mt-6" />
                    <div className="text-center text-xl">
                        <div className="bg-price-blue  text-white [clip-path:polygon(0%_0%,100%_0%,95%_50%,100%_100%,0%_100%,5%_50%)]">窗型冷氣</div>
                        <p className="mt-2">基本方案 $4500/台</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Card;
