import { IconTicket } from '@tabler/icons'
import { NaviButtons } from '../NaviButtons'
import { Pagination } from '../Pagination'
import { useRecoilState } from 'recoil'
import { couponAtom } from '../../stores/reservation'

export const Q14 = () => {
  const [coupon, setCoupon] = useRecoilState(couponAtom)
  return (
    <>
      <div className="mx-6 sm:ml-16 xl:ml-60">
        <div className="mb-12">
          <Pagination></Pagination>
          <h1>
            若您持有
            <IconTicket className="inline mx-1 text-sky-500" />
            禮券，請填寫
            <span className="text-jw-blue font-medium underline leading-loose decoration-sky-500 underline-offset-6">
              【抵用券代碼】
            </span>
          </h1>
          <p>包含英文及數字。如果沒有就不用填唷！</p>
        </div>
      </div>

      <div className="text-center mb-12 w-fit mx-6 sm:ml-16 xl:ml-60">
        <input
          name="coupon"
          type="text"
          placeholder="請輸入抵用券代碼"
          value={coupon}
          onChange={(e) => {
            setCoupon(e.target.value)
          }}
          required
          className="text-center w-52 sm:w-72 outline-none focus:w-64 sm:focus:w-96 focus:ease-in-out focus:duration-300 border-b-2 border-jw-blue"
        />
      </div>

      <NaviButtons></NaviButtons>
    </>
  )
}
