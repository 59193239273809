import { useRecoilValue } from 'recoil'
import { formStepAtom } from '../stores/reservation'
import { Q02 } from '../components/forms/Q02'
import { Q03 } from '../components/forms/Q03'
import { Q01 } from '../components/forms/Q01'
import { Q04 } from '../components/forms/Q04'
import { Q05 } from '../components/forms/Q05'
import { Q06 } from '../components/forms/Q06'
import { Q07 } from '../components/forms/Q07'
import { Q08 } from '../components/forms/Q08'
import { Q09 } from '../components/forms/Q09'
import { Q10 } from '../components/forms/Q10'
import { Q11 } from '../components/forms/Q11'
import { Q12 } from '../components/forms/Q12'
import { Q13 } from '../components/forms/Q13'
import { Q14 } from '../components/forms/Q14'
import { Q15 } from '../components/forms/Q15'
import { Q16 } from '../components/forms/Q16'
import { Q17 } from '../components/forms/Q17'

export const FormPage = () => {
  const formStep = useRecoilValue(formStepAtom)

  return (
    <>
      {formStep === 1 && <Q01 />}
      {formStep === 2 && <Q02 required />}
      {formStep === 3 && <Q03 />}
      {formStep === 4 && <Q04 />}
      {formStep === 5 && <Q05 />}
      {formStep === 6 && <Q06 />}
      {formStep === 7 && <Q07 />}
      {formStep === 8 && <Q08 />}
      {formStep === 9 && <Q09 />}
      {formStep === 10 && <Q10 />}
      {formStep === 11 && <Q11 />}
      {formStep === 12 && <Q12 />}
      {formStep === 13 && <Q13 />}
      {formStep === 14 && <Q14 />}
      {formStep === 15 && <Q15 />}
      {formStep === 16 && <Q16 />}
      {formStep === 17 && <Q17 />}
    </>
  )
}
